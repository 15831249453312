import Error from "@/core/services/Error";
import {request} from "@/core/services/Request";
import {getEventId} from "@/util/globalFunction";

const DEFAULT_FORM_STATE = {
    title: null,
    guest_id: [],
    task_completion_time: null,
    event_id: getEventId() ? getEventId() : null,
    status: false,
    _method: 'post',
};

const COLUMN_DEFINATION = () => [
    {
        text: "#",
        sortable: false,
        value: 'id',
    },
    {
        text: "Task Name",
        sortable: false,
        value: 'title',
    },
    {
        text: "Assign To",
        sortable: false,
        value: 'guests',
    },
    {
        text: "Task Completion Date",
        sortable: false,
        value: 'task_completion_date',
    },
    {
        text: "Task Completion Time",
        sortable: false,
        value: 'task_completion_time',
    },
    {
        text: "Status",
        sortable: false,
        value: 'status',
    },
    {
        text: "Action",
        value: "action",
        sortable: false,
    },

];

export default {
    data() {
        return {
            operationTitle: 'Todos',
            formFields: {...DEFAULT_FORM_STATE},
            formErrors: new Error({}),
            listUrl: '/sub/guest/users/todo/lists',
            columns: COLUMN_DEFINATION(this),
            dropdowns: {
                fromTime: [
                    '00:00',
                    '01:00',
                    '01:30',
                    '02:00',
                    '02:30',
                    '03:00',
                    '03:30',
                    '04:00',
                    '04:30',
                    '05:00',
                    '05:30',
                    '06:00',
                    '06:30',
                    '07:00',
                    '07:30',
                    '08:00',
                    '08:30',
                    '09:00',
                    '09:30',
                    '10:00',
                    '10:30',
                    '11:00',
                    '11:30',
                    '12:00',
                    '12:30',
                    '13:00',
                    '13:30',
                    '14:00',
                    '14:30',
                    '15:00',
                    '15:30',
                    '16:00',
                    '16:30',
                    '17:00',
                    '17:30',
                    '18:00',
                    '18:30',
                    '19:00',
                    '19:30',
                    '20:00',
                    '20:30',
                    '21:00',
                    '21:30',
                    '22:00',
                    '22:30',
                    '23:00',
                    '23:30',
                ],
                guests: [],
                events: [],
            },
            status: true,
        }
    },
    methods: {
        async handleSubmitOperation() {
            this.formErrors = new Error({})
            try {
                const response = await request({
                    url: this.formFields.id ? '/sub/guest/users/todo/lists/update' : '/sub/guest/users/todo/lists/create',
                    method: 'post',
                    data: this.formFields,
                })
                if (this.formFields.id) {
                    this.$global.itemUpdated()
                } else {
                    this.$global.itemAdded()
                }
                this.handleOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }
                this.$global.handleServerError(error)
            }

        },
        async handleDeleteOperation(id) {
            let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
                primaryBtnText: 'Yes',
                secondaryBtnText: 'No'
            });
            if (deleteResponse) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/sub/guest/users/todo/lists/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.$global.itemDeleted()
                } catch (errors) {
                    this.$global.handleServerError(errors)
                }
            }
        },
        async handleEditOperation(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/sub/guest/users/todo/lists/detail/${id}`,
                })
                const {data} = response.data
                this.formFields = {
                    ...data,
                    event_id: data.event ? data.event.id : null,
                    guest_id: data.guests ? data.guests.data.map((item) => item.id) : [],
                    status: !!data.status,
                }
            } catch (e) {
                this.$global.itemEditFails()
                this.formFields = {...DEFAULT_FORM_STATE}
            }
        },
        async getEventByGuests() {
            try {
                const response = await request({
                    url: `/dropdowns/sub/guest/user/${getEventId()}`,
                    method: 'post',
                })

                const {data} = response.data
                this.dropdowns.guests = data.map((item) => {
                    return {
                        id: item.id,
                        label: item.name + '(' + item.phone + ')',
                    }
                })
            } catch (e) {

            }
        },
        handleOperationClose() {
            this.formFields = {...DEFAULT_FORM_STATE}
            this.$router.push({name: "todoList"})
        },
    },
}
