<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{formFields.id ? `Edit ${operationTitle}` : `Add ${operationTitle}`}}
          </template>
          <template v-slot:toolbar>
            <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                      size="sm"
                      variant="primary"
                      :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button variant="danger" size="sm" class="mt-3"
                      @click="handleOperationClose()">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>
          <template v-slot:body>
            <v-card>
              <div class="todolists-operation">
                <b-container fluid>
                  <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                    <b-row>
                      <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
                        <b-row>
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('title')"
                                label="Name of the Task *"
                                label-for="title"
                            >
                              <b-form-input
                                  :state="((formErrors.has('title') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="title"
                                  type="text"
                                  v-model="formFields.title"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('guest_id')"
                                label="Assign Guest"
                                label-for="guest_id"
                            >
                              <treeselect
                                  :class="[{'invalid is-invalid': (formErrors.has('guest_id'))}]"
                                  :multiple="true"
                                  :options="dropdowns.guests"
                                  id="guest_id"
                                  v-model="formFields.guest_id"
                              />
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row><!--/b-row-->
                        <b-row>
                          <b-col sm="6">
                            <b-form-group
                                label="Task Completion Time"
                                label-for="task_completion_time ">
                              <treeselect
                                  :options="_.map(dropdowns.fromTime,(i) => {
                                                                    return {
                                                                        id:i,
                                                                        label:i
                                                                    }
                                                                })"
                                  v-model="formFields.task_completion_time"
                              />
                              <span class="text-danger"
                                    v-if="formErrors.has('task_completion_time')">
                                                                {{ formErrors.first("task_completion_time") }}
                                                            </span>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="6">
                            <b-form-group
                                label="Task Completion Date"
                                label-for="task_completion_date">
                              <b-form-datepicker
                                  :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                  close-button
                                  id="task_completion_date"
                                  reset-button
                                  today-button
                                  type="text"
                                  v-model="formFields.task_completion_date">
                                >
                              </b-form-datepicker>
                              <b-form-invalid-feedback
                                  :state="(!(formErrors.has('task_completion_date')))">
                                {{ formErrors.first('task_completion_date') }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row>
                        <b-row>
                          <b-col sm="6">
                            <b-form-group
                                label="Status"
                                label-for="status">
                              <b-form-checkbox
                                  :unchecked-value="false"
                                  :value="true"
                                  v-model="formFields.status"
                              >
                                {{
                                formFields.status ? 'Completed' : new
                                Date(formFields.task_completion_date).getTime() >=
                                Date.now() ? 'OnGoing' : 'OverDue'
                                }}
                              </b-form-checkbox>
                              <b-form-invalid-feedback
                                  :state="(!(formErrors.has('status')))">
                                {{ formErrors.first('status') }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <hr />
                        <b-row class="operation-footer">
                          <b-col sm="12">
                            <b-button
                                :disabled="global.pendingRequests > 0"
                                size="sm"
                                type="submit"
                                variant="primary"
                            >
                              <i class="fa fa-spin fa-spinner"
                                 v-show="global.pendingRequests > 0"></i>
                              <i class="fa fa-save fa-sm"></i>
                              Save
                            </b-button>
                            <b-button @click="handleOperationClose()" class="ml-3"
                                      size="sm"
                                      variant="danger">
                              <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col><!--/b-col-->
                    </b-row><!--/b-row-->
                  </form><!--/form-->
                </b-container><!--/b-container-->
              </div><!--/.todolists-operation-->
            </v-card>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
  import KTCard from "@/view/content/Card.vue";
  import moduleMixin from "./module.mixin";
  import { mapState } from "vuex";

  export default {
    mixins: [moduleMixin],
    components: {
      KTCard,
    },
    mounted() {
      this.getEventByGuests();

      if ( this.$route.params && this.$route.params.id && this.$route.params.type ) {
        this.handleEditOperation(this.$route.params.id);
      }
    },
    computed: {
      ...mapState([
        'global',
      ]),
    },
  };
</script>
